import React from 'react';
import './style.scss';

const Post = ({pageContext}) => (

    <div>
        <h1>{pageContext.title}</h1>
        <p>pageContext.content</p>
    </div>

)
export default Post;